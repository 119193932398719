import React from "react";
import Banner from "../../ValooresNew/components/General/Banner/Banner";
// import Intro from '../components/General/Intro/Intro'
// import Sections from "../components/VFDS/Sections/Sections";
// import Compliance from "../components/VFDS/Compliance/Compliance";
// import Vfds from "../assets/img/banking.jpg";
// import Services from "../components/VFDS/Services/Services";
// import Insights from "../components/VFDS/Insights/Insights";
import VtechBanner from "../../ValooresNew/assets/img/vtechbanner1.jpg";
// import VtechBanner from "../../ValooresNew/assets/img/VtechBanner.png";
import VtechPrincipal from "../../ValooresNew/assets/img/vtech_principal.jpg";
import VtechLogo from "../../ValooresNew/assets/img/VTech-logo-white.png";
// import Banner from '../../Components/servicesGroup/Banner';
import Fade from "react-reveal/Fade";
import Intro from "../../admin/IntroServices/Introduction";
import SectionArray from "../../ValooresNew/components/Vtech/Sections/sectionsArray";
import NewRequestDemo from "../../ValooresNew/components/General/RequestDemo/NewRequestDemo";
import dataRole from "./vtechArray";

const VtechBannerTitle = (
  <>
    <img src={VtechLogo} className="vfds_banner-width" alt="" />
  </>
);

const Animation = () => {
  return (
    <>
      <div className="retail__content-all container mt-5">
        <div className="dataRole_section d-flex flex-column">
          <h1 className="text-center mb-5">
            Powering Innovation with Advanced Technologies
          </h1>
          <p>
            V-tech harnesses a robust ecosystem of high-performance technologies
            to deliver real-time insights, secure data processing, and seamless
            automation:
          </p>
          {dataRole.map((data, index) => (
            <div
              key={index}
              className={`dataRole_section-part d-flex justify-content-between ${
                index % 2 === 0 ? "flex-row-reverse" : ""
              }`}
            >
              {index % 2 === 0 ? (
                <Fade left>
                  {
                    <div
                      className="p-4 w-40 dataRole_section-part_imgvtech"
                      style={{ backgroundImage: `url(${data.img})`, flex: "2" }}
                    ></div>
                  }
                </Fade>
              ) : (
                <Fade right>
                  {
                    <div
                      className="p-4 w-40 dataRole_section-part_imgvtech"
                      style={{ backgroundImage: `url(${data.img})`, flex: "2" }}
                    ></div>
                  }
                </Fade>
              )}
              <div className="seperator"></div>
              <div className="p-4 w-40 my-3" style={{ flex: "2" }}>
                <h4>{data.title}</h4>
                <h6>{data.desc}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const VTECH = () => {
  return (
    <div>
      <Banner
        title={VtechBannerTitle}
        img={VtechBanner}
        desc={
          <>
            VALOORES proudly presents V-tech—a cutting-edge suite of AI and <br/>
            machine learning solutions that revolutionize intelligence,<br/>
            security, compliance, and digital transformation.
          </>
        }
        descSize="2rem"
        classType="vfdsClass"
      />

      <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up">
          <Intro title="V-TECH" />
          <div>
            <h2 className="my-5">
              VALOORES proudly presents V-tech—a cutting-edge suite of AI and
              machine learning solutions that revolutionize intelligence,
              security, compliance, and digital transformation. With over 35
              years of expertise, VALOORES seamlessly integrates advanced
              technologies to optimize operations across banking, insurance,
              retail, supply chain, financial services, and government agencies.
            </h2>
          </div>
          {/* <div>
            <h2 className='my-5'>Elevating Performance with Next-Generation Solutions</h2>
            <p >VFDS data processing platform to integrate diverse data streams, enhance compliance with AI-driven analytics, and ensure top-tier information security across all solutions</p>
          </div> */}
        </Fade>
      </div>
      <div style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
        <hr
          className="my-5"
          style={{ opacity: "0.5", height: "0.3rem", backgroundColor: "#000" }}
        />
        <div className="row">
          <Animation />
        </div>
      </div>
      <div
        className="my-5"
        style={{
          backgroundImage: `url(${VtechPrincipal})`,
          height: "500px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      {SectionArray.map((section, index) => (
        <React.Fragment key={index}>
          <div className="d-flex flex-column text-left mt-4 p-3 container">
            <Fade direction="up">
              <div>
                <h2 className="my-5">{section.title}</h2>
                <p>{section.desc}</p>
              </div>
            </Fade>
          </div>
          <div
            className="my-5"
            style={{
              backgroundImage: `url(${section.img})`,
              height: "500px",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </React.Fragment>
      ))}
      <NewRequestDemo />

      {/* <div
        className="mt-5"
        style={{
          backgroundImage: `url(${IMAG5})`,
          height: "500px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      ></div> */}
    </div>
  );
};

export default VTECH;

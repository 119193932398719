import React, { useEffect, useRef, useState } from 'react'
import Banner from './Banner'
import Vfds1 from '../../img/vfds/vfds1.png'
import IMAG1 from '../../img/VCMS/IMAG1.png'
import IMAG2 from '../../img/VCMS/IMAG2.png'
import IMAG4 from '../../img/VCMS/IMAG4.png'
import IMAG5 from '../../img/VCMS/IMAG5.png'
import { Fade } from 'react-awesome-reveal';
import Intro from "../../admin/IntroServices/Introduction";


  const ServicesGroup = () => {
      return (
      <div>
        <Banner/>
        <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up" >
        <Intro title="V-FDS"/>
          <div>
            <h2 className='my-5'>Introducing the Valoores Financial Digital Services (VFDS): Powering Seamless Compliance in a Complex Financial World</h2>
            <p >The Valoores Regulatory Compliance Suite provides a comprehensive set of solutions for financial services, ensuring global compliance adherence and scalable programs while delivering dynamic support for effective compliance controls against Financial Crime and regulatory reporting requirements.
            </p>
          </div>
          <div>
            <h2 className='my-5'>Elevating Performance with Next-Generation Solutions</h2>
            <p >VFDS data processing platform to integrate diverse data streams, enhance compliance with AI-driven analytics, and ensure top-tier information security across all solutions</p>
          </div>
          </Fade>
        </div>
        <div className='my-5' style={{backgroundImage: `url(${Vfds1})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>
        <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up" >
          <div>
          <h2 className='my-5'>Know Your Customer</h2>
            <p >
              KYC solution is a smart, orchestrated tool that streamlines the collection, verification, and maintenance of customer data, utilizing advanced document verification and geo-smart location technology for agile address verification.
            </p>
            <ul>
              <li>Industry and International Standards</li>
              <li>Local and Multi-Jurisdictional Laws and Regulations</li>
              <li>All Types of Financial Services Institutions, financial products, and customer types.</li>
            </ul>
          </div>
          <div>
            <h2 className='my-5'>Screening </h2>
            <p>Valoores Sanctions and PEP Screening Engine is a customizable solution that adapts to evolving regulations and risk profiles, featuring advanced name matching and timely list ingestion for optimal compliance.
            </p>
            <ul>
              <li>Enable real-time protection via customers and transaction screening;</li>
              <li>Provide automated retroactive screening upon receiving watch list updates;</li>
              <li>Provide a modern, yet centralized system of record that enables Financial Institutions to access, investigate and analyze potential hi  ts holistically through appropriate user interfaces that can easily integrate with existing systems. </li>
            </ul>
          </div>
          </Fade>
        </div>
        <div className='my-5' style={{backgroundImage: `url(${IMAG1})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>

        <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up" >
          <div>
          <h2 className='my-5'>Transaction Monitoring</h2>
            <p > Advanced risk-scoring features, designed to trigger risk-based behaviors aligned with your Financial Institution´s risk management strategy, and allowing flexibility in automating alert resolution or escalation. 
</p>
            <ul>
              <li>Enables seamless rules, scenarios and parameters customization without over reliance on software developers;</li>
              <li>Provides real-time alerts to flag suspicious behavior as soon as possible;</li>
              <li>Monitors historic transactions and social relationships between accounts or customers using a library of AI models to create an AI driven risk-score that flags activities requiring further investigation;</li>
              <li>Uses unsupervised AI models to suggest a refinement or recalibration for rules, scenarios or parameters which reduces false positives.</li>
            </ul>
          </div>
          <div>
            <h2 className='my-5'>Regulatory Reporting </h2>
            <p>fully customizable templates, multi-jurisdictional support, sophisticated regulatory submissions model, our service is also equipped to cater for:</p>
            <ul>
              <li>Offer a dynamic approach for integrating business rules and dictionaries;</li>
              <li>Provide regulatory submissions available in different formats;</li>
              <li>Guarantees best-in-class accessibility, traceability and other security features.</li>
            </ul>
            <p>Trusted by our partners and customers since over 35 years, choosing our solutions puts your financial institution ahead of the curve not just in complying with regulations and global regulatory standards FATF, but also equips you with an agile infrastructure that combines technical innovation with practical application to propel your business within the rapidly evolving landscape of financial services.</p>
          </div>
          </Fade>
        </div>
        <div className='my-5' style={{backgroundImage: `url(${IMAG2})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>

        <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up" >
          <div>
            <h2 className='my-5'>Customer Risk Assessment per a Risk-Based Approach (RBA) </h2>
            <p >A dynamic logic tool for assessing AML/CFT risks, detecting unusual patterns and potential suspicions related to customer activities, while allowing for risk-level overrides to align with approved risk appetites and regulatory requirements.</p>
          </div>
          <div>
            <h2 className='my-5'>Fraud Risk Management </h2>
            <p >There is no shortage of ingenuity when it comes to financial crime and fraud. We see this daily as fraudsters continuously adapt their approach and methods in an effort to stay one step ahead of the law and the latest detection technologies. The velocity, complexity, and expanded opportunity for financial crime require financial services organizations to rethink and retool their approach to fraud management. Real - time and enterprise - wide define the gold standard toward which today's firms aspire and this can be accomplished with VALOORES in' Fraud Management<br/>
             Key Benefits</p>
          <ul>
              <li>Rapidly detect and prevent complex fraud schemes to minimize losses, maximize customer trust and reduce reputational risk</li>
              <li>Intelligently focus investigators on the most urgent and actionable fraud alerts to prevent and reduce losses from high exposure activities</li>
              <li>Combat fraud with a comprehensive set of prebuilt scenarios from an industry proven financial crime library used in over 70 countries</li>
          </ul>
          </div>
          </Fade>
        </div>
        <div className='my-5' style={{backgroundImage: `url(${IMAG4})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>

        <div className="d-flex flex-column text-left mt-4 p-3 container">
          <Fade direction="up" >
          <div>
            <h2 className='my-5'>Payment </h2>
            <p >VALOORES in' Payment is a complete payment and receipt processing solution that enables efficient, reliable and secure financial transactions. It lowers costs and improves control by integrating out-of-the-box with major processors and financial institutions.<br /><br />
Key Benefits</p>
            <ul>
              <li>Improve visibility and agility with centralized payments</li>
              <li>Reduce costs with streamlined processes</li>
              <li>Maintain complete control over payment data and instruments</li>
              </ul>
              <p>Key Features</p>
              <ul>
              <li>Central payment engine</li>
              <li>Configurable formatting framework</li>
              <li>Flexible validation model</li>
              <li>Secure payment data repository</li>
              <li>Seamless electronic payment transmission</li>
              <li>Flexible support for various Business Payment Models</li>
              <li>Dashboards for monitoring payment processes</li>
          </ul>
          </div>
          <div>
            <h2 className='my-5'>IFRS </h2>
            <p >IFRS 9 “Regulatory Tsunami” comes to succeed IAS 39 by introducing new requirements and models for financial institutions. Financial Instruments are now perceived differently while the guidelines are divided under 3 main pillars: Classification & Measurement, Impairment, and Hedge Accounting. VALOORES adopted IFRS 9 as the flagship product of its compliance and risk solutions focusing on the pain arising from the market from an implementation perspective, as well as the governance aspect of the challenges. While IFRS 9 cannot be considered as “another headache for the risk department”, many still tackle the new regulatory pressures from a risk perspective only.To be able to “demystify” the IFRS 9 guidelines and apply a smooth transition between IAS39 incurred models and IFRS 9 expected, forward-looking models, one must embrace IFRS 9 as a discipline rather than a set of new rules. VALOORES did exactly that to come up with an end to end solution set and creating the “IFRS 9 Ecosystem” simplifying the roadmap to implementation.With January '18 around the corner, many banks and financial institutions are ready to invest in IFRS 9 without accurately studying their IFRS 9 needs. IFRS 9 comes as a subjective judgmental compliance and can be subjectively implemented at each entity depending on their needs and how “IFRS 9 ready” the entity is.</p>
          </div>
          </Fade>
        </div>
        <div className='mt-5' style={{backgroundImage: `url(${IMAG5})`, height: '500px', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>
      </div>
    )
  }

export default ServicesGroup
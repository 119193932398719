import React from "react";
import Banner from "../components/General/Banner/Banner";
// import Intro from '../components/General/Intro/Intro'
import Sections from "../components/VFDS/Sections/Sections";
import Compliance from "../components/VFDS/Compliance/Compliance";
import Vfds from "../assets/img/banking.jpg";
import Services from "../components/VFDS/Services/Services";
import Insights from "../components/VFDS/Insights/Insights";
import VtechBanner from "../assets/img/VtechBanner.png";
import VtechPrincipal from "../assets/img/vtech_principal.jpg";
import VtechLogo from "../assets/img/VTech-logo-white.png";
// import Banner from '../../Components/servicesGroup/Banner';
import Vfds1 from "../../img/vfds/vfds1.png";
import IMAG1 from "../../img/VCMS/IMAG1.png";
import IMAG2 from "../../img/VCMS/IMAG2.png";
import IMAG4 from "../../img/VCMS/IMAG4.png";
import IMAG5 from "../../img/VCMS/IMAG5.png";
import { Fade } from "react-awesome-reveal";
import Intro from "../../admin/IntroServices/Introduction";
import SectionArray from "../../ValooresNew/components/Vtech/Sections/sectionsArray";
import NewRequestDemo from "../components/General/RequestDemo/NewRequestDemo";

const VtechBannerTitle = (
  <>
    <img src={VtechLogo} className="vfds_banner-width" alt="" />
  </>
);
const VTECH = () => {
  return (
    <div>
      <Banner
        title={VtechBannerTitle}
        img={VtechBanner}
        classType="vfdsClass"
      />
      <div className="d-flex flex-column text-left mt-4 p-3 container">
        <Fade direction="up">
          <Intro title="V-TECH" />
          <div>
            <h2 className="my-5">
              A holistic and nimble Compliance Solutions Suite catering for all
              financial institutions: Fintechs, crypto exchanges, virtual assets
              service providers, payment service providers, E-wallet providers
              and more…VFDS is adaptable to any financial products, services &
              customer profiles.
            </h2>
          </div>
          {/* <div>
            <h2 className='my-5'>Elevating Performance with Next-Generation Solutions</h2>
            <p >VFDS data processing platform to integrate diverse data streams, enhance compliance with AI-driven analytics, and ensure top-tier information security across all solutions</p>
          </div> */}
        </Fade>
      </div>
      <div
        className="my-5"
        style={{
          backgroundImage: `url(${VtechPrincipal})`,
          height: "500px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      {SectionArray.map((section, index) => (
        <React.Fragment key={index}>
          <div className="d-flex flex-column text-left mt-4 p-3 container">
            <Fade direction="up">
              <div>
                <h2 className="my-5">{section.title}</h2>
                <p>{section.desc}</p>
              </div>
            </Fade>
          </div>
          <div
            className="my-5"
            style={{
              backgroundImage: `url(${section.img})`,
              height: "500px",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </React.Fragment>
      ))}
      <NewRequestDemo />

      {/* <div
        className="mt-5"
        style={{
          backgroundImage: `url(${IMAG5})`,
          height: "500px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      ></div> */}
    </div>
  );
};

export default VTECH;

import React from 'react'
import Banner from '../components/General/Banner/Banner'
import Intro from '../components/General/Intro/Intro'
import RequestDemo from '../components/General/RequestDemo/RequestDemo'
import VtechBanner from '../assets/img/VtechBanner.png'
import VtechLogo from '../assets/img/VTech-logo-white.png'
import Sections from '../components/Vtech/Sections/Sections'
import Introduction from "../../admin/IntroServices/Introduction";

const VtechBannerTitle = (
    <>
        <img src={VtechLogo} className='vfds_banner-width' alt="" />
    </>
)

const Vtech = () => {
  return (
    <div>
        <Banner title={VtechBannerTitle} img={VtechBanner} desc={'VALOORES proudly presents V-tech—a cutting-edge suite of AI and machine learning solutions that revolutionize intelligence, security, compliance, and digital transformation.'} descSize={'1rem'} classType='vfdsClass'/>
        <Introduction title="V-TECH"/>
        <Intro desc='A holistic and nimble Compliance Solutions Suite catering for all financial institutions: Fintechs, crypto exchanges, virtual assets service providers, payment service providers, E-wallet providers and more…VFDS is adaptable to any financial products, services & customer profiles.' />
        <Sections />
        <RequestDemo />
    </div>
  )
}

export default Vtech
import React, { useState } from 'react';
import './NewRequestDemo.css'; 

const NewRequestDemo = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: ''
    // message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); 
  };

  return (
    <div className="request-demo-container my-2 d-flex flex-column justify-content-center align-items-center my-5">
        <div className='d-flex flex-column align-items-center justify-content-center'>
            <h1 className="form-title font--poppins fw-bold p-0 m-0" style={{color: '#001f3d!important'}}>Delivering Customized, High-Impact Solutions.</h1>
            <p className='fw-light w-75 text-center my-4' style={{color: '#001f3d!important'}}>Transform your operations with VALOORES Consulting, offering tailored solutions across banking, finance, insurance, government, and retail sectors. </p>
        </div>
      <form className="request-demo-form rounded d-flex flex-lg-row align-items-center gap-3 m-4" onSubmit={handleSubmit}>
        <div className="form-group">
          <input 
            type="text" 
            id="fullName" 
            name="fullName" 
            className='form-control'
            value={formData.fullName} 
            onChange={handleChange} 
            required 
            placeholder="Full Name"
          />
        </div>
        <div className="form-group">
          <input 
            type="email" 
            id="email" 
            name="email" 
            className='form-control'
            value={formData.email} 
            onChange={handleChange} 
            required 
            placeholder="Business Email"
          />
        </div>
        <div className="form-group">
          <input 
            type="tel" 
            id="phoneNumber" 
            name="phoneNumber" 
            className='form-control'
            value={formData.phoneNumber} 
            onChange={handleChange} 
            required 
            placeholder="Phone Number"
          />
        </div>
          <button type="submit" className="submit-btn">Contact Us</button>
      </form>
    </div>
  );
};

export default NewRequestDemo;

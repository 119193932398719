import Vtech1 from "../../../assets/img/Vtech1.png";
import Vtech2 from "../../../assets/img/Vtech2.png";
import Vtech3 from "../../../assets/img/Vtech3.png";
import Vtech4 from "../../../assets/img/vtechlast.jpg";

const sectionsArray = [
  {
    img: Vtech1,
    title: "V-tech Ecosystem: VCIS | VRCS | VFDS | VCMS",
    desc: (
      <>
        <p>
          V-tech is the foundation of multiple VALOORES systems, each designed
          to serve specialized industry needs:
        </p>

        <h2>VCIS (VALOORES Crowd Intelligence System)</h2>
        <ul>
          <li>
            <strong>Core Functionalities:</strong> AI-powered intelligence
            gathering, predictive risk assessment, and behavioral profiling.
          </li>
          <li>
            <strong>Real-Time Event Detection & Response:</strong> Automated
            anomaly detection and proactive security alerts for financial
            institutions and government agencies.
          </li>
          <li>
            <strong>Technical Architecture & Infrastructure:</strong> Scalable
            microservices, distributed databases, and API-driven integration for
            high-performance analytics.
          </li>
        </ul>

        <h2>VRCS (V-Retail & Supply Chain)</h2>
        <ul>
          <li>
            <strong>Key Capabilities:</strong> AI-driven demand forecasting,
            automated inventory optimization, and real-time supply chain
            monitoring.
          </li>
          <li>
            <strong>Technological Innovations:</strong> IoT integration, smart
            logistics, blockchain-powered traceability, and dynamic pricing
            models.
          </li>
        </ul>

        <h2>VFDS (VALOORES Financial Digital Services)</h2>
        <ul>
          <li>
            <strong>Key Capabilities:</strong> AI-driven financial risk
            assessment, automated lending solutions, and advanced fraud
            detection.
          </li>
          <li>
            <strong>Technology Innovations:</strong> Blockchain-powered digital
            transactions, real-time KYC verification, and smart contract
            automation for financial institutions.
          </li>
        </ul>

        <h2>VCMS (VALOORES Crypto Management System)</h2>
        <ul>
          <li>
            <strong>Key Capabilities:</strong> Secure crypto asset storage,
            AI-powered risk analytics, and regulatory compliance enforcement.
          </li>
          <li>
            <strong>Technology & Innovation:</strong> Decentralized identity
            management, blockchain forensic analysis, and next-generation
            AML/KYC compliance for digital assets.
          </li>
        </ul>
      </>
    ),
    link: "",
  },
  {
    img: Vtech2,
    title: "Core Capabilities",
    desc: (
      <>
        <h2>Advanced Analytics & AI-Driven Insights</h2>
        <p>
          AI-powered models continuously process vast datasets to detect hidden
          correlations, uncover fraud, and identify emerging risks. The
          combination of deep learning and NLP allows systems to extract
          actionable intelligence from structured and unstructured data sources.
        </p>

        <h2>Predictive Intelligence Framework</h2>
        <p>
          Time series analysis, regression models, and probabilistic algorithms
          forecast potential threats, financial risks, and market fluctuations,
          empowering organizations to make data-driven strategic decisions.
        </p>

        <h2>Real-Time Intelligence & Threat Detection</h2>
        <p>
          AI-enhanced event-driven architectures monitor transactions,
          behaviors, and communications in real-time, issuing instant alerts on
          anomalies, security breaches, or operational inefficiencies.
        </p>

        <h2>Geospatial Intelligence & Pattern Recognition</h2>
        <p>
          Advanced GIS and spatial-temporal analytics identify criminal
          hotspots, monitor fraudulent financial activities across regions, and
          optimize asset allocation for law enforcement and financial
          institutions.
        </p>

        <h2>Regulatory Compliance & Risk Mitigation</h2>
        <p>
          AI-automated compliance checks ensure adherence to AML/KYC guidelines,
          GDPR, and evolving global financial regulations, minimizing exposure
          to regulatory penalties.
        </p>
      </>
    ),
    link: "",
  },
  {
    img: Vtech4,
    title: "Transforming Data into Intelligence",
    desc: (
      <>
        <h1>Transforming Data into Intelligence</h1>
        <p>
          V-tech’s AI-driven architecture streamlines decision-making across
          industries, enabling:
        </p>

        <h2>Fraud Detection & Prevention</h2>
        <p>
          Deep learning models analyze vast transaction patterns, detecting
          hidden anomalies in real-time to prevent fraudulent activities before
          they escalate.
        </p>

        <h2>Automated Decision-Making</h2>
        <p>
          AI-driven recommendation engines optimize operational workflows by
          identifying inefficiencies and suggesting corrective actions in
          real-time.
        </p>

        <h2>Risk Management & Security</h2>
        <p>
          Predictive analytics enhances cybersecurity posture by detecting
          vulnerabilities and mitigating potential threats across networks and
          digital infrastructures.
        </p>

        <h2>Customer Intelligence & Market Insights</h2>
        <p>
          Behavioral analytics refine personalization strategies, optimize
          customer engagement, and enable hyper-targeted service offerings.
        </p>

        <h1>Use Cases: Real-World Applications</h1>
        <ul>
          <li>
            <strong>Financial Crime Prevention:</strong> AI-powered AML
            investigations, risk-based fraud detection, and compliance
            automation.
          </li>
          <li>
            <strong>Counter-Terrorism & Border Security:</strong> AI-driven
            tracking of suspicious movements, transactions, and digital
            communications.
          </li>
          <li>
            <strong>Retail Optimization:</strong> AI-powered demand forecasting,
            consumer behavior analysis, and intelligent inventory management.
          </li>
          <li>
            <strong>Critical Infrastructure Protection:</strong> AI-driven
            cybersecurity for financial networks, smart cities, and global
            supply chains.
          </li>
        </ul>

        <p>
          V-tech is the next frontier in AI-powered intelligence, empowering
          businesses and governments with actionable insights, real-time
          decision-making, and future-proof security solutions.
        </p>
      </>
    ),
    link: "",
  },
  // {
  //     img: Vtech1,
  //     title: 'Analytics',
  //     desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
  //     link: '',
  // },
  // {
  //     img: Vtech2,
  //     title: 'BIG DATA',
  //     desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
  //     link: '',
  // },
  // {
  //     img: Vtech3,
  //     title: 'Machine Learning',
  //     desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
  //     link: '',
  // },
  // {
  //     img: Vtech1,
  //     title: 'Analytics',
  //     desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
  //     link: '',
  // },
  // {
  //     img: Vtech2,
  //     title: 'BIG DATA',
  //     desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
  //     link: '',
  // },
  // {
  //     img: Vtech3,
  //     title: 'Machine Learning',
  //     desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
  //     link: '',
  // },
];

export default sectionsArray;

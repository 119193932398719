import vtech1 from '../../img/VCIS/postt.jpg';
import vtech2 from '../../img/VCIS/casandra.jpg';
import vtech3 from '../../img/VCIS/kafka.jpg';
import vtech4 from '../../img/VCIS/spark.jpg';
import vtech5 from '../../img/VCIS/nifi.jpg';
import vtech6 from '../../img/VCIS/superset.jpg';
import vtech7 from '../../img/VCIS/ai_driven.jpg';
import vtech8 from '../../img/VCIS/dev.jpg';
import vtech9 from '../../img/VCIS/wso2.png';
import vtech10 from '../../img/VCIS/ciam.jpg';
import vtech11 from '../../img/VCIS/angular.jpg';
import vtech12 from '../../img/VCIS/airflow.jpg';
import vtech13 from '../../img/VCIS/redmine.jpg';
// import vtech4 from '../../img/VCIS/spark.jpg';
// import vtech4 from '../../img/VCIS/spark.jpg';
// import vtech5 from '../../img/retail/data/su.jpg';
const dataRole = [
    {
      title: 'PostgreSQL',
      desc: 'A powerful open-source relational database management system ensuring high-performance, ACID compliance, and extensibility with JSON, key-value stores, and full-text search capabilities.',
      img: vtech1,
    },
    {
      title: 'Apache Cassandra',
      desc: 'A highly scalable, distributed NoSQL database designed for handling large volumes of structured and unstructured data, ensuring continuous availability and fault tolerance.',
      img: vtech2,
    },
    {
      title: "Apache Kafka",
      desc: "A distributed event streaming platform that enables high-throughput, fault-tolerant real-time data ingestion and processing across microservices, data lakes, and hybrid cloud environments.",
      img: vtech3,
    },
    {
      title: 'Apache NiFi',
      desc: 'A robust data integration tool that automates data ingestion, transformation, and movement between distributed systems with real-time analytics.',
      img: vtech4,
    },
    {
      title: 'Apache Spark',
      desc: 'A powerful, distributed computing framework enabling large-scale data analytics, real-time stream processing, and machine learning model training.',
      img: vtech5,
    },
    {
      title: 'Apache Superset',
      desc: 'A modern data visualization and business intelligence tool that transforms complex datasets into interactive dashboards and reports for enhanced decision-making.',
      img: vtech6,
    },
    {
      title: 'AI-Driven Analytics',
      desc: 'Advanced machine learning models utilizing supervised and unsupervised learning for anomaly detection, predictive modeling, and behavioral analytics.',
      img: vtech7,
    },
    {
      title: 'DevSecOps',
      desc: 'Integrating security into the software development lifecycle to enforce continuous security monitoring, automated vulnerability detection, and policy-driven compliance enforcement.',
      img: vtech8,
    },
    {
      title: 'WSO2 API Management',
      desc: 'A flexible, open-source API management platform that ensures secure, scalable, and policy-driven API governance.',
      img: vtech9,
    },
    {
      title: 'CIAM (Customer Identity & Access Management)',
      desc: 'Implements multi-factor authentication, consent management, and secure identity federation to safeguard digital identities.',
      img: vtech10,
    },
    {
      title: 'Angular 18',
      desc: 'A modern front-end framework optimizing web application performance, ensuring modular architecture, and enhancing UI/UX responsiveness.',
      img: vtech11,
    },
    {
      title: 'Apache Airflow',
      desc: 'A workflow orchestration platform automating data pipeline scheduling, dependency management, and real-time monitoring.',
      img: vtech12,
    },
    {
      title: 'Redmine',
      desc: 'A flexible project management tool with integrated issue tracking, agile workflows, and real-time collaboration features.',
      img: vtech13,
    },
  ]
export default dataRole;